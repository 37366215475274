.containerForContainer{
  display: flex;
  flex-direction: column;
  gap: 60px;
  transition: all 1s;
  transform: translateX(150px);
  opacity: 0;
}
.transfor{
    transform: translateX(0) !important;
    opacity: 1 !important;
}
.textContainer{
    background-color: rgb(16, 27, 29);
    width: 360px;
    height: 210px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 500;
    text-align: justify;
    color: rgb(137, 206, 255);
    padding: 0 30px;
    transition: all 1s;
    transform: translateX(-150px);
    opacity: 0;
}
.mainContainer{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
}
.edukat{
    text-align: center;
    color: rgba(128, 255, 255, 0.848);
    font-size: 38px;
}
@media screen and (max-width: 870px) {
    .mainContainer{ 
    flex-direction: column;
    gap: 70px;
    }
    .textContainer{
    width: 340px;
    padding: 0 10px;
    }
    
}