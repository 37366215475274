.ProjSbox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: start;
    gap: 90px;
    margin: 0 auto;
}
.imgcontainer{
    width: 360px;
    height: 430px;
    background-color: rgb(15, 15, 15);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transform: translateX(100px);
    opacity: 0;
    transition: all 1s;
}

.image{
    width: 360px;
    height: 173px;
    background-size: cover; /* Зображення покриває весь контейнер */
    background-position: center; /* Центрує зображення */
    background-repeat: no-repeat; /* Зображення не повторюється */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

}
.discription{
    color: white;
    font-size: 14px;
    text-align: justify;
    width: 90%;
    font-weight: 500;
}
.stuffContainer{
    display: flex;
    align-items: center;
    background-color: rgb(15, 15, 15);
    color: white;
    font-size: 26px;
    width: 320px;
    height: 55px;
    margin: 35px 0;
    padding: 0 20px;
    border-radius: 15px;
    transform: translateY(100px);
    opacity: 0;
    transition: all 1s;
}
.stuffContainer2{
    display: flex;
    align-items: center;
    background-color: rgb(15, 15, 15);
    color: white;
    font-size: 26px;
    width: 320px;
    height: 55px;
    margin: 35px 0;
    padding: 0 20px;
    border-radius: 15px;
    transform: translateY(-100px);
    opacity: 0;
    transition: all 1s;
}

.stuffIusehere{
    color: white;
    font-size: 32px;
    transform: translateX(-100px);
    opacity: 0;
    transition: all 1s;
}
.projectName{
    text-align: center;
    color:rgba(0, 255, 255, 0.759);
    font-size: 35px;
    margin-top: 100px;
    margin-bottom: 40px;
}
.move-left{
    transform: translateX(0px) !important;
    opacity: 1 !important;
}