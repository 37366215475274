.foto{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 330px;
  background-color: black;
  border-radius: 1000px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  margin-bottom: 15px;
  transition: opacity 1s;
  opacity: 0;
}
#mee{
  width: 354px;
  height: 327px;
}
#sosos{
 display: flex;
 justify-content: center;
 width: 300px;
}
.Abou{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px ;
  padding-bottom: 100px;
}
.HiItsMe{
  color: rgba(144, 162, 255, 1);
  text-align: center;
  margin-top: 40px;
  transition: opacity 1s;
  opacity: 0;
}
.samllTextAboutMe{
  color: rgba(144, 162, 255, 1);
  text-align: justify;
  font-weight: 500;
  font-size: 18px;
  width: 346px;
  transition: opacity 1s;
  opacity: 0;
}
.name{
  font-size: 40px;
  color: rgba(144, 162, 255, 1);
  text-align: center;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 15px;
  opacity: 0;
  transition: opacity 1s;
}

.navigationContainer{
  display: flex;
  gap: 10px;
}
