body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  font-family: Inter;
}
/* Для WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Ширина повзунка */
}

::-webkit-scrollbar-track {
  background: #000000cf; /* Колір фону треку */
}

::-webkit-scrollbar-thumb {
  background: #4abcb4aa; /* Колір самого повзунка */
  box-shadow: 1px 1px 30px white;
  border-radius: 1px; /* Закруглення */
}

::-webkit-scrollbar-thumb:hover {
  background: #1849ba99; /* Колір при наведенні */
}
