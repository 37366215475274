.continer{
    color:rgb(0, 0, 0);
    background-color: rgb(16, 27, 29);
    width: 350px;
    height: 210px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 0px;
}
.mt{
    font-size: 30px;
    color: rgba(70, 174, 232, 1);
    margin: 25px 0 10px;
}
.st{
    color: rgba(255, 165, 59, 0.92);
    font-size: 26px;
    margin: 0px;
}
.tt{
    color: white;
    font-size: 22px;
    margin: 0;
    margin-top: 15px;
}
.ft{
    margin-top: 15px;
    color: azure;
}