.inp{
    background-color: rgba(166, 217, 255, 0.36);
    border: none;
    width: 340px;
    border-radius: 2px;
    font-size: 17px;
    font-weight: 600;
    color: rgb(255, 221, 221);
    padding: 10px;
    border-radius: 10px;
}
.inp::placeholder{
  color: rgba(235, 64, 64, 0.837);
}
.wish-container{
  width: 360px;
}
.peaceContainer{
margin: 20px 0px;
display: flex;
flex-direction: column;
gap: 5px;
}
#mas{
  height: 120px !important;
  resize: none;
}
.wish{
  font-size: 28px;
  color: white;
}
.wish-text{
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-align: justify;
}
.inp:focus{
    outline: none; 
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

    -webkit-text-fill-color: rgb(255, 221, 221); /* Колір тексту */
    transition: background-color 5000s ease-in-out 0s; /* Для запобігання зміни фону */
}
.lab{
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 26px;
}


  button{
    height: 50px;
    text-align: center;
    width: 230px;
    border-radius: 1000px;
    background: rgba(93, 129, 172, 0.738);
    border: 2px solid var(--green);
    color: var(--green);
    letter-spacing: 1px;
    text-shadow: 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.25s ease;
    color: rgb(255, 255, 255);
    transition: all 0.5s;
  }
  .shape{
    height: 100px !important;
    width: 100px !important;
  }
  .but-cor{
    opacity: 0.7;
  }
  .but-ror{
    opacity: 1;
  }
  .but-ror:hover{
    background-color: rgba(11, 152, 51, 0.711);
    color: rgba(255, 255, 255, 0.966);
  }
  .but-cor:hover {
    background-color: rgba(152, 11, 11, 0.711);
    color: rgba(255, 255, 255, 0.966);
  }
  
  button:active {
    letter-spacing: 1.5px;
  }
  svg path{
    stroke-width: 3;
    stroke: #fffffff9;
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
    stroke-linecap: round;
    stroke-dashoffset: 0;
   
}
.opa{
  opacity: 0 !important;
}
.apo{
  opacity: 1 !important;
}
.cont1{
  transition: all 1s;
}
.cont2{
opacity: 0;
color: aqua;
position: relative;
transition: all 1s;
}
.cont2 svg{
  width: 50px;
  position: absolute;
  right: 20px;
  top:-35px;
}

.formContainer{
  min-width: 320px;
  max-width: 800px;
  background-color: rgb(11, 11, 11);
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 74px !important;
  gap: 60px;
  padding: 30px 45px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.erorr{
  font-size: 18px;
  color: rgb(0, 187, 255);
}
@media screen and (max-width: 430px) {
  .inp{
    width: 300px;
    color: white;
  }
}
@media screen and (max-width: 869px) {
  .formContainer{
    width: 50%;
    padding: 30px 35px;
  }
}