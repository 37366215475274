.infotxt{
    margin-top: 140px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 60px;
    color: rgba(128, 255, 255, 0.848);
    text-align: center;
}
.txtx{
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    width: 80%;
    color: white;
}
.work{
    font-size: 28px;
    color: rgba(128, 247, 255, 0.879);
    margin: 0;
    margin-bottom: 20px;
}
.show{
    opacity: 1 !important;
    transform: translateY(0) !important;
}
.box2{
    margin-top: 50px; 
    display: flex;
    flex-direction: column;
    background-color: rgb(16, 27, 29);
    height: 270px;
    min-width: 360px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all 1s;
    transform: translateY(150px);
    opacity: 0;
}
.freelance{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(16, 27, 29);
    color: rgba(162, 255, 255, 1);
    font-size: 26px;
    min-width: 270px;
    height: 150px;
    font-weight: 600;
    border-radius: 15px;
    transition: all 1s;
    transform: translateY(150px);
    opacity: 0;
}
.mainbox{
    display: flex;
    gap: 120px;
    justify-content: center;
}
@media screen and (max-width: 820px){
    .mainbox{
       flex-direction: column;
       gap: 60px;
       align-items: center;
    }
    
}