.navigButtom{
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100px;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    border: 1px solid;
    color: rgba(255, 255, 255, 0.744);
    font-size: 20px;
    position: relative;
    overflow: hidden;
    background: transparent;
    text-transform: uppercase;
    transition: all .3s;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .delay{
    opacity: 1 !important;
  }
  .navigButtom:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    background: rgba(227, 227, 227, 0.184);
    z-index: -1;
    transition: all .3s;
  }
  .navigButtom:after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background: rgba(255, 255, 255, 0.058);
    z-index: -1;
    transition: all .3s;
    
  }
  
  .navigButtom:before{
    opacity: .5;
  }
  
  .navigButtom:after{
    transition-delay: .0s;
  }
  
  .navigButtom:hover{
    color: #ffffff;
    box-shadow: 1px 1px 20px rgba(255, 255, 255, 0.375);
    z-index: 100;
  }
  
  .navigButtom:hover:before{
    right: 0px;
  }
  .navigButtom:hover:after{
    left: 0px;
  }