
.resume{
  display: flex;
  justify-content: center;
  align-items: center;
    margin-top: 75px;
    font-size: 44px;
    text-align: center;
    color: rgba(255, 255, 255, 0.607);
    margin-bottom: 100px;
    text-shadow: .5px .5px 50px rgba(255, 255, 255, 0.414);
}
.delay{
  opacity: 1;
}
.resume::before {
    content: "";
    width: 34px;
    height: 34px;
    background-color: rgb(116, 180, 248);
    margin-right: 8px;
    border-radius: 6px;
}
.maonbox{
  opacity: 0;
    width: 55%;
    margin: 0 auto;
    transition: all 1s;
}
  @media screen and (max-width: 480px) {
    .maonbox{
        width: 80%;
        margin: 0 auto;
    }
  }
  
  /* Середні мобільні екрани */
  @media screen and (min-width: 481px) and (max-width: 767px) {
    .maonbox{
        width: 80%;
        margin: 0 auto;
    }
  }
  
  /* Планшети в вертикальному режимі */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .maonbox{
        width: 80%;
        margin: 0 auto;
    }
  }
  
  /* Планшети в горизонтальному режимі */
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    /* Ваші стилі тут */
  }
