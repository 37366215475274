#language{
    position: absolute;
    color: rgba(255, 255, 255, 0.27);
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 10px 10px;
    background-color: rgba(0, 0, 0, 0.203);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}
option{
    color: rgb(255, 255, 255);
    background-color: black;
}
.start-up{
    background-image: url("./up-arrow.png");
        width: 80px;
        height: 80px;
        background-size: cover;
        background-position: center;
        position: fixed;
        top: 520px;
        right: 50px;
        border-radius: 20px;
        cursor: pointer;
}

.maim{
    opacity: 1;
    transition: all 0.1s;
    overflow: hidden;
}
.opo{
    opacity: 0;
}
/* .raketa{
    position: absolute;
    z-index: -1;
    width: 200px;
    animation: exampleAnimation 4s ease-in-out 1 forwards;
    top: 0;
    left: 0;
}
@keyframes exampleAnimation {
    0% {
      transform: translateX(0) rotate(180deg) translateY(0px) scale(1); /* Start state 
      opacity: 0;
    }
    50%{
        transform: translateX(0px) rotate(180deg) translateY(-500px) scale(1); 
     
    }
    60%{
       transform: rotate(90deg) translateY(0px) translateX(500px) scale(1);
    }
    100% {
        transform: rotate(90deg) translateY(-1200px) translateX(500px) scale(2);
      opacity: 1;
    } 
} */