
.box3{
    padding-top: 30px;
    display: flex;
    border-radius: 15px;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    align-items: space-around;
}
.tran{
    opacity: 1 !important;
}
.boxig{
    background-color: rgb(16, 27, 29);
    width: 340px;
    height: 237px;
    border-radius: 15px;
    opacity: 0;
    transition: all 1s;
}
.secondtxt{
      font-size: 25px;
      margin-left: 22px;
      color: rgba(44, 237, 255, 0.622);
}
.items{
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 600;
    color: rgba(0, 179, 255, 0.771);
    margin: 5px 0;
}
.items::before{
    content: "";
    width: 19px;
    height: 19px;
    background-color: rgba(0, 255, 255, 0.834);
    border-radius: 5px;
    margin-right: 7px;
}
.custom-list {
    list-style-type: square; 
    gap: 20px;
    padding-left: 30px;
}

.skill{
    margin-top: 100px;
    margin-bottom: 100px;
}
