 #gj{
   width: 35px;  /* Задайте бажану ширину */
   height: 35px; /* Задайте бажану висоту */
   background-size: cover;
   background-position: center;
   margin: 5px; /* Відступи між елементами */
 }
 #gjd{
  display: flex;
  justify-content: center;
  align-items: center;
 }