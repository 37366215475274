.maintxt{
    display: flex;
    justify-content: center;
    align-items: center;
      margin-top: 75px;
      font-size: 44px;
      text-align: center;
      color: rgba(255, 255, 255, 0.607);
      margin-bottom: 100px;
      text-shadow: .5px .5px 50px rgba(255, 255, 255, 0.414);
}
.maintxt::before{
    content: "";
    width: 34px;
    height: 34px;
    background-color: rgb(116, 180, 248);
    margin-right: 8px;
    border-radius: 6px;
}
.projectbox{
     opacity: 0;
     transition: all 1s;
}
.active{
    opacity: 1;
}