.navi{
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    
}
.Iteem{
    list-style-type: none;
    cursor: pointer;
}
.Iteem.active{
    margin: 0 21.5px;
    color: rgb(228, 244, 255);
    text-shadow: 1px 1px 40px rgb(110, 110, 110);
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
}
.Iteem.pasive{
    margin: 0 21.5px;
    text-decoration: none;
    color: rgba(180, 222, 240, 0.516);
    font-weight: 600;
    text-transform: uppercase;
}
.Iteem.pasive:hover{
    color: rgb(193, 229, 255);
    text-shadow: 1px 1px 40px rgb(110, 110, 110);
}
#logo{
    width: 120px;
    height: 60px;
    background-size: cover;
    background-position: center;
    margin: 5px 5px 5px 20px;
}
#navig{
    display: flex;
    justify-content:space-between;
    align-items: center;
    background: linear-gradient(to bottom,rgba(15, 211, 255, 0.070) 0%, rgba(0, 0, 0, 0) 100%);
    margin-bottom: 25px;
    height: 80px;
    padding-bottom: 30px;
}
.linee1{
    width: 45px;
    height: 6px;
    background-color: rgba(203, 35, 35, 0.70);
    border-radius: 10px;
    z-index: 3;
  
}
.move1{
    animation: moveee 0.8s forwards;

}
.move1back{
    animation: moveeebackeee 0.8s forwards;
}
.linee2{
    width: 45px;
    height: 6px;
    background-color: rgba(255, 229, 0, 0.70);
    border-radius: 10px;
    z-index: 3;
}
.move2{
    animation: visiblee 0.5s forwards;
}
.move2back{
    animation: visibleee 0.8s forwards;
}
@keyframes visiblee{
    from{
        opacity: 1;
    }
    to{
        opacity: -1;
    }

}
@keyframes visibleee{
    from{
        opacity: -1;
    }
    to{
        opacity: 1;
    }

}
.linee3{
    width: 45px;
    height: 6px;
    background-color: rgba(74, 194, 72, 0.70);
    border-radius: 10px;
    z-index: 3;
 
}
.move3{
    animation: movee 0.8s forwards;
}
.move3back{
    animation: moveebackee 0.8s forwards;
}
@keyframes movee{
    from{
        transform: rotate(0deg) translateY(-0px);
       
    }
    to{
        transform: translateY(-12px) rotate(225deg);
        background-color: rgba(203, 35, 35, 0.70);
        
    }

}
@keyframes moveebackee{
    from{
        transform: translateY(-12px) rotate(225deg);
        
    }
    to{
        transform: rotate(360deg) translateY(-0px);
        background-color: rgba(203, 35, 35, 0.70);
    }

}
@keyframes moveeebackeee{
    from{
        transform: translateY(12px) rotate(135deg);
        
    }
    to{
        
        transform: rotate(360deg) translateY(-0px);
    }
}
@keyframes moveee{
    from{
        transform: rotate(0deg) translateY(-0px);
       
    }
    to{
        transform: translateY(12px) rotate(135deg);
        
    }
}

#burger{
    display: flex;
    flex-direction: column;
    width: 40px;
    gap: 6px;
    margin-right: 25px;
    padding: 5px;
   
}
.menu {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 20px;
    gap: 30px;
    text-align: center;
    background-color: rgba(4, 4, 4, 0.951);
    right: -100px;
    animation: mmenu 0.1s linear forwards;
    padding: 20px 30px;
    z-index: 3;
    border-top-left-radius: 15px ;
    border-bottom-left-radius: 15px ;
}
.menu .Iteem{
  text-decoration: none;
  color: azure;
}
@keyframes mmenu {
    from {
        right: -100px;
    }
    to {
        right: 0;
    }
}
#menucontainer{
    position: absolute;
    right: -4px;
    top: 70px;
    overflow: hidden;
   
}
@media screen and (max-width: 480px) {
    #logo{
        margin: 5px 5px 5px 10px;
    }
    #navig{
       margin: 0px;
       padding: 0px;
    }
}
  /* Середні мобільні екрани */
  @media screen and (min-width: 481px) and (max-width: 767px) {
    #logo{
        margin: 5px 5px 5px 10px;
    }
    #navig{
        margin: 0px;
        padding: 0px;
     }
  }
  
  /* Планшети в вертикальному режимі */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    #logo{
        margin: 5px 5px 5px 10px;
    }
    #navig{
        margin: 0px;
        padding: 0px;
     }
  }