.foottxt{
    width: 18%;
    font-size: 18px;
}
.line{
    margin: 0 auto;
    width: 98%;
    height: 5px;
    background-color: rgba(47, 0, 255, 0.514);
    border-radius: 5px;
}
.foote{
    height: 130px;
    font-weight: 550;
    color: white;
}
.cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    height: 50px;
    gap: 10px;
}
.cont *{
    margin: 0;
}
.Conect{
    display: flex;
    justify-content: right;
    align-items: center;
    width: 80%;
   gap: 30px;
}
.Content{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto 0;
}
#call{
  color: aliceblue;
}
#mail{
 color: aliceblue;
}
.cont #gj{
    width: 30px;  /* Задайте бажану ширину */
    height: 30px; /* Задайте бажану висоту */
    background-size: cover;
    background-position: center;
    margin: 5px; /* Відступи між елементами */
  }
  @media screen and (max-width: 480px) {
    .foottxt{
        width: 20%;
        font-size: 10px;
    }
    .co{
        font-size: 16px;
    }
    .cont #gj{
        width: 18px;  /* Задайте бажану ширину */
        height: 18px; /* Задайте бажану висоту */
        background-size: cover;
        background-position: center;
        margin: 5px; /* Відступи між елементами */
      }
      #mail{
        font-size: 8px;
      }
     
    #call{
        font-size: 7px;
      }
      .Conect{
        width: 90%;
        margin-right: 10px;
        gap: 30px;
    }
    .cont{
       margin-right: 2px;
    }
  }
  
  /* Середні мобільні екрани */
  @media screen and (min-width: 481px) and (max-width: 767px) {
    .foottxt{
        width: 20%;
        font-size: 12px;
    }
    .co{
        font-size: 18px;
    }
    .cont #gj{
        width: 22px;  /* Задайте бажану ширину */
        height: 22px; /* Задайте бажану висоту */
        background-size: cover;
        background-position: center;
        margin: 5px; /* Відступи між елементами */
      }
      #mail{
        font-size: 10px;
      }
      #call{
        font-size: 10px;
      }
    .Conect{
        width: 90%;
        margin-right: 10px;
        gap: 40px;
    }
  }
  
  /* Планшети в вертикальному режимі */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .foottxt{
        width: 20%;
        font-size: 13px;
    }
    #mail{
        font-size: 12px;
      }
      .Conect{
        width: 90%;
        margin-right: 10px;
        gap: 40px;
    }
     
  }